const defaultProps = {
  width: 98,
  height: 30,
  color: '#1C1C1C',
  alt: 'snipfeed logo',
}

interface LogoWordmarkProps {
  width?: number
  height?: number
  color?: string
  alt?: string
  className?: string
}

export default function LogoWordmark({
  width,
  height,
  color,
  alt,
  className,
}: LogoWordmarkProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 561 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      color={color}
    >
      {alt && <title>{alt}</title>}

      <g clipPath="url(#clip0_625_337)">
        <path
          d="M248.934 123.591C247.984 124.516 246.943 125.223 245.813 125.711C244.708 126.199 243.462 126.443 242.075 126.443C240.072 126.443 238.492 125.929 237.336 124.902C236.205 123.874 235.64 122.525 235.64 120.856C235.64 119.494 236.026 118.325 236.796 117.349C237.567 116.347 238.71 115.564 240.226 114.999C241.741 114.408 243.604 114.048 245.813 113.92L248.818 113.689C248.973 113.663 249.114 113.612 249.242 113.534C249.371 113.457 249.435 113.316 249.435 113.111V112.07C249.435 110.683 248.998 109.591 248.125 108.795C247.277 107.973 246.121 107.562 244.657 107.562C243.527 107.562 242.499 107.819 241.574 108.333C240.675 108.846 239.866 109.617 239.147 110.645C239.044 110.773 238.954 110.863 238.877 110.914C238.826 110.94 238.723 110.927 238.569 110.876L236.488 110.182C236.411 110.131 236.347 110.067 236.295 109.989C236.27 109.912 236.308 109.771 236.411 109.566C237.182 108.23 238.261 107.177 239.648 106.406C241.061 105.635 242.782 105.25 244.811 105.25C246.532 105.25 247.958 105.52 249.088 106.059C250.244 106.573 251.105 107.331 251.67 108.333C252.261 109.309 252.556 110.478 252.556 111.839V125.48C252.556 125.711 252.518 125.865 252.441 125.942C252.363 126.019 252.235 126.058 252.055 126.058H250.09C249.91 126.058 249.782 126.006 249.705 125.903C249.628 125.775 249.576 125.621 249.551 125.441L249.512 123.669C249.435 123.309 249.242 123.283 248.934 123.591ZM249.435 116.27C249.435 115.834 249.255 115.628 248.896 115.654L246.352 115.885C245.171 115.936 244.117 116.09 243.193 116.347C242.268 116.579 241.484 116.887 240.842 117.272C240.2 117.657 239.712 118.12 239.378 118.659C239.044 119.173 238.877 119.777 238.877 120.47C238.877 121.601 239.262 122.487 240.033 123.129C240.829 123.746 241.895 124.054 243.231 124.054C244.053 124.054 244.837 123.913 245.582 123.63C246.352 123.322 247.02 122.936 247.585 122.474C248.176 121.986 248.626 121.485 248.934 120.971C249.268 120.458 249.435 119.957 249.435 119.468V116.27Z"
          fill={color}
        />
        <path
          d="M554.4 108.063C554.194 108.063 554.092 108.166 554.092 108.371V120.586C554.092 121.793 554.297 122.602 554.708 123.013C555.119 123.425 555.736 123.63 556.558 123.63H559.216C559.345 123.63 559.448 123.669 559.525 123.746C559.602 123.797 559.64 123.9 559.64 124.054L559.602 125.364C559.602 125.621 559.448 125.788 559.139 125.865C558.883 125.916 558.51 125.955 558.022 125.981C557.56 126.006 557.097 126.032 556.635 126.058C556.198 126.058 555.826 126.058 555.517 126.058C553.899 126.058 552.717 125.685 551.972 124.94C551.227 124.169 550.855 122.962 550.855 121.318V108.448C550.855 108.191 550.726 108.063 550.47 108.063H547.387C547.104 108.063 546.963 107.922 546.963 107.639V106.021C546.963 105.764 547.092 105.635 547.348 105.635H550.624C550.803 105.635 550.893 105.533 550.893 105.327L551.317 99.2003C551.343 98.8664 551.484 98.6994 551.741 98.6994H553.668C553.976 98.6994 554.13 98.8792 554.13 99.2389V105.327C554.13 105.533 554.233 105.635 554.438 105.635H559.294C559.576 105.635 559.717 105.751 559.717 105.982V107.639C559.717 107.922 559.576 108.063 559.294 108.063H554.4Z"
          fill={color}
        />
        <path
          d="M538.243 105.25C539.579 105.25 540.748 105.468 541.75 105.905C542.777 106.316 543.651 106.894 544.37 107.639C545.115 108.358 545.731 109.155 546.219 110.028C546.374 110.336 546.309 110.529 546.027 110.606L543.908 111.107C543.728 111.184 543.561 111.107 543.407 110.876C542.893 110.131 542.379 109.527 541.865 109.065C541.377 108.602 540.851 108.256 540.285 108.024C539.72 107.767 539.104 107.639 538.436 107.639C537.126 107.639 535.97 107.973 534.968 108.641C533.992 109.283 533.234 110.221 532.695 111.454C532.155 112.687 531.885 114.151 531.885 115.846C531.885 117.568 532.155 119.045 532.695 120.278C533.26 121.511 534.03 122.448 535.007 123.091C536.008 123.733 537.177 124.054 538.513 124.054C539.746 124.054 540.812 123.746 541.711 123.129C542.61 122.513 543.432 121.613 544.177 120.432C544.254 120.303 544.319 120.239 544.37 120.239C544.447 120.213 544.55 120.226 544.678 120.278L546.335 120.894C546.515 120.971 546.566 121.138 546.489 121.395C546.104 122.063 545.654 122.705 545.141 123.322C544.627 123.938 544.023 124.478 543.33 124.94C542.662 125.403 541.904 125.775 541.056 126.058C540.208 126.314 539.271 126.443 538.243 126.443C536.342 126.443 534.66 126.006 533.195 125.133C531.757 124.234 530.627 123.001 529.805 121.434C528.983 119.841 528.572 118.004 528.572 115.923C528.572 113.817 528.983 111.967 529.805 110.375C530.652 108.782 531.795 107.536 533.234 106.637C534.698 105.712 536.368 105.25 538.243 105.25Z"
          fill={color}
        />
        <path
          d="M515.544 123.9C516.648 123.9 517.701 123.604 518.703 123.013C519.731 122.397 520.746 121.575 521.747 120.547V106.213C521.747 105.828 521.953 105.635 522.364 105.635H524.483C524.843 105.635 525.023 105.815 525.023 106.175L524.946 125.557C524.946 125.891 524.804 126.058 524.522 126.058H522.287C522.004 126.058 521.863 125.916 521.863 125.634V123.36C521.863 123.232 521.837 123.155 521.786 123.129C521.735 123.103 521.645 123.142 521.516 123.245C520.103 124.452 518.845 125.287 517.74 125.749C516.661 126.212 515.582 126.443 514.503 126.443C513.322 126.443 512.268 126.199 511.344 125.711C510.419 125.223 509.687 124.542 509.147 123.669C508.608 122.769 508.338 121.729 508.338 120.547V106.252C508.338 105.841 508.531 105.635 508.916 105.635H511.074C511.408 105.635 511.575 105.815 511.575 106.175V119.7C511.575 120.958 511.922 121.973 512.615 122.744C513.334 123.514 514.311 123.9 515.544 123.9Z"
          fill={color}
        />
        <path
          d="M501.224 126.058C501.044 126.058 500.903 126.019 500.8 125.942C500.723 125.839 500.684 125.698 500.684 125.518V123.9C500.684 123.694 500.633 123.579 500.53 123.553C500.453 123.527 500.337 123.591 500.183 123.746C499.901 124.003 499.49 124.349 498.95 124.786C498.436 125.223 497.781 125.608 496.985 125.942C496.189 126.276 495.238 126.443 494.134 126.443C492.515 126.443 491.064 126.058 489.779 125.287C488.495 124.516 487.467 123.373 486.697 121.858C485.952 120.316 485.579 118.415 485.579 116.155C485.579 113.971 485.952 112.07 486.697 110.452C487.467 108.808 488.534 107.536 489.895 106.637C491.282 105.712 492.849 105.25 494.596 105.25C495.855 105.25 496.908 105.455 497.756 105.866C498.603 106.277 499.348 106.753 499.991 107.292C500.222 107.472 500.376 107.549 500.453 107.523C500.556 107.472 500.607 107.305 500.607 107.022V97.9673C500.607 97.6333 500.748 97.4664 501.031 97.4664H503.381C503.664 97.4664 503.805 97.6333 503.805 97.9673V125.441C503.805 125.672 503.767 125.839 503.69 125.942C503.613 126.019 503.446 126.058 503.189 126.058H501.224ZM500.569 110.144C499.772 109.347 498.963 108.731 498.141 108.294C497.319 107.857 496.394 107.639 495.367 107.639C494.134 107.639 493.016 107.96 492.014 108.602C491.038 109.219 490.268 110.156 489.702 111.415C489.163 112.674 488.893 114.215 488.893 116.039C488.893 117.914 489.176 119.456 489.741 120.663C490.306 121.845 491.064 122.718 492.014 123.283C492.965 123.823 494.005 124.092 495.135 124.092C496.189 124.092 497.113 123.848 497.91 123.36C498.732 122.872 499.374 122.269 499.836 121.549C500.325 120.83 500.569 120.124 500.569 119.43V110.144Z"
          fill={color}
        />
        <path
          d="M473.34 126.443C471.414 126.443 469.705 126.006 468.215 125.133C466.751 124.234 465.608 123.001 464.786 121.434C463.99 119.841 463.591 118.017 463.591 115.962C463.591 113.83 464.002 111.967 464.824 110.375C465.672 108.756 466.828 107.498 468.292 106.599C469.757 105.7 471.452 105.25 473.379 105.25C475.331 105.25 477.026 105.712 478.465 106.637C479.929 107.536 481.06 108.795 481.856 110.413C482.678 112.006 483.089 113.843 483.089 115.923C483.089 117.979 482.678 119.802 481.856 121.395C481.034 122.962 479.891 124.195 478.426 125.094C476.988 125.993 475.292 126.443 473.34 126.443ZM473.417 124.015C474.753 124.015 475.896 123.694 476.847 123.052C477.797 122.384 478.516 121.447 479.004 120.239C479.518 119.006 479.775 117.542 479.775 115.846C479.775 114.151 479.518 112.7 479.004 111.492C478.491 110.259 477.759 109.309 476.808 108.641C475.858 107.973 474.715 107.639 473.379 107.639C472.069 107.639 470.925 107.973 469.949 108.641C468.973 109.309 468.215 110.259 467.676 111.492C467.162 112.7 466.905 114.151 466.905 115.846C466.905 117.516 467.162 118.968 467.676 120.201C468.215 121.434 468.973 122.384 469.949 123.052C470.925 123.694 472.081 124.015 473.417 124.015Z"
          fill={color}
        />
        <path
          d="M452.22 126.058C452.04 126.058 451.899 126.019 451.796 125.942C451.719 125.839 451.68 125.711 451.68 125.557V106.136C451.68 105.802 451.822 105.635 452.104 105.635H454.339C454.622 105.635 454.763 105.777 454.763 106.059V108.564C454.763 108.718 454.801 108.808 454.879 108.833C454.956 108.859 455.058 108.808 455.187 108.679C455.855 107.883 456.51 107.241 457.152 106.753C457.82 106.239 458.488 105.866 459.156 105.635C459.824 105.378 460.491 105.25 461.159 105.25C462.058 105.25 462.508 105.417 462.508 105.751V108.063C462.508 108.32 462.392 108.422 462.161 108.371C461.93 108.345 461.66 108.32 461.352 108.294C461.069 108.268 460.761 108.256 460.427 108.256C459.862 108.256 459.258 108.384 458.616 108.641C457.974 108.872 457.37 109.206 456.805 109.643C456.266 110.079 455.816 110.567 455.457 111.107C455.097 111.646 454.917 112.186 454.917 112.725V125.48C454.917 125.865 454.724 126.058 454.339 126.058H452.22Z"
          fill={color}
        />
        <path
          d="M432.503 105.635C432.863 105.635 433.043 105.828 433.043 106.213V107.832C433.043 108.037 433.081 108.153 433.158 108.178C433.261 108.178 433.39 108.114 433.544 107.986C433.826 107.678 434.212 107.318 434.7 106.907C435.188 106.47 435.817 106.085 436.588 105.751C437.384 105.417 438.386 105.25 439.593 105.25C441.16 105.25 442.573 105.635 443.832 106.406C445.116 107.151 446.131 108.281 446.876 109.797C447.647 111.312 448.032 113.226 448.032 115.538C448.032 117.85 447.66 119.815 446.915 121.434C446.17 123.052 445.129 124.298 443.793 125.171C442.483 126.019 440.942 126.443 439.17 126.443C437.988 126.443 436.935 126.263 436.01 125.903C435.111 125.518 434.34 125.056 433.698 124.516C433.467 124.311 433.3 124.234 433.197 124.285C433.12 124.311 433.081 124.465 433.081 124.747V131.915C433.081 132.248 432.94 132.415 432.658 132.415H430.269C429.986 132.415 429.845 132.248 429.845 131.915V106.252C429.845 106.021 429.883 105.866 429.96 105.789C430.063 105.687 430.23 105.635 430.461 105.635H432.503ZM433.081 121.549C433.878 122.32 434.687 122.936 435.509 123.399C436.357 123.861 437.294 124.092 438.322 124.092C439.555 124.092 440.647 123.771 441.597 123.129C442.573 122.487 443.331 121.536 443.871 120.278C444.436 119.019 444.718 117.452 444.718 115.577C444.718 113.701 444.436 112.173 443.871 110.991C443.305 109.81 442.548 108.949 441.597 108.41C440.672 107.87 439.645 107.6 438.515 107.6C437.461 107.6 436.524 107.832 435.702 108.294C434.88 108.756 434.237 109.334 433.775 110.028C433.313 110.696 433.081 111.364 433.081 112.032V121.549Z"
          fill={color}
        />

        <g clipPath="url(#clip1_625_337)">
          <path
            d="M367.46 104.826C361.341 104.826 356.364 109.803 356.364 115.922C356.364 122.04 361.341 127.017 367.46 127.017C373.578 127.017 378.555 122.04 378.555 115.922C378.555 109.803 373.578 104.826 367.46 104.826ZM367.46 122.235C363.971 122.235 361.135 119.399 361.135 115.911C361.135 112.422 363.971 109.586 367.46 109.586C370.948 109.586 373.784 112.422 373.784 115.911C373.784 119.399 370.948 122.235 367.46 122.235Z"
            fill={color}
          />
          <path
            d="M280.238 104.826C277.891 104.826 275.717 105.565 273.913 106.815V105.413H269.142V135.711H273.913V125.039C275.706 126.289 277.891 127.028 280.238 127.028C286.356 127.028 291.334 122.051 291.334 115.932C291.334 109.814 286.356 104.837 280.238 104.837M280.238 122.246C276.749 122.246 273.913 119.41 273.913 115.922C273.913 112.433 276.749 109.597 280.238 109.597C283.726 109.597 286.563 112.433 286.563 115.922C286.563 119.41 283.726 122.246 280.238 122.246Z"
            fill={color}
          />
          <path
            d="M321.197 106.804C319.404 105.554 317.22 104.815 314.872 104.815C308.754 104.815 303.777 109.792 303.777 115.911C303.777 122.029 308.754 127.006 314.872 127.006C317.22 127.006 319.393 126.267 321.197 125.018V126.495H325.968V105.402H321.197V106.804ZM314.872 122.235C311.384 122.235 308.547 119.399 308.547 115.911C308.547 112.422 311.384 109.586 314.872 109.586C318.361 109.586 321.197 112.422 321.197 115.911C321.197 119.399 318.361 122.235 314.872 122.235Z"
            fill={color}
          />
          <path
            d="M299.951 96.208H295.181V126.506H299.951V96.208Z"
            fill={color}
          />
          <path
            d="M387.151 96.1102H382.381V126.409H387.151V96.1102Z"
            fill={color}
          />
          <path
            d="M409.31 105.402V116.019C409.31 119.475 406.517 122.268 403.061 122.268C399.605 122.268 396.813 119.475 396.813 116.019V105.402H392.042V116.378C392.042 122.257 396.823 127.039 402.703 127.039C405.191 127.039 407.495 126.18 409.31 124.746V135.7H414.081V105.402H409.31Z"
            fill={color}
          />
          <path
            d="M342.226 104.869C339.737 104.869 337.433 105.728 335.618 107.162V105.402H330.847V126.506H335.618V115.889C335.618 112.433 338.411 109.64 341.867 109.64C345.323 109.64 348.116 112.433 348.116 115.889V126.506H352.886V115.53C352.886 109.651 348.105 104.869 342.226 104.869Z"
            fill={color}
          />
          <path
            d="M356.897 96.0124L355.375 92.4479C355.103 91.8176 354.212 91.8176 353.941 92.4479L352.419 96.0124C352.343 96.1972 352.191 96.3493 352.006 96.4254L348.442 97.9468C347.811 98.2185 347.811 99.1096 348.442 99.3813L352.006 100.903C352.191 100.979 352.343 101.131 352.419 101.316L353.941 104.88C354.212 105.511 355.103 105.511 355.375 104.88L356.897 101.316C356.973 101.131 357.125 100.979 357.31 100.903L360.874 99.3813C361.504 99.1096 361.504 98.2185 360.874 97.9468L357.31 96.4254C357.125 96.3493 356.973 96.1972 356.897 96.0124Z"
            fill={color}
          />
        </g>
        <path
          d="M1.06479 59.35H24.6848C24.5948 62.35 26.4248 65.82 36.2448 65.82C49.1548 65.82 50.6048 64.08 50.6048 61.67C50.6948 59.07 49.3548 58.01 37.3048 57.52L26.3248 57.05C4.64479 56.07 -0.175207 48.54 0.00479296 40.33C0.194793 28.06 7.61479 24.11 36.0348 24.11C70.7248 24.11 72.9348 30.96 72.7448 41.01V41.79H49.1448C48.8648 40.04 48.1748 36.67 36.4248 36.67C26.0148 36.67 23.8048 37.26 23.7048 40.33C23.7048 42.74 25.8248 43.9 35.5548 44.29L46.4348 44.76C66.8548 45.63 74.3648 49.6 74.1748 60.31C73.9848 73.64 68.5948 78.37 36.4948 78.37C2.01479 78.38 0.764793 70.66 0.954793 60.04L1.04479 59.36H1.06479V59.35Z"
          fill={color}
        />
        <path
          d="M100.585 25.74L101.555 36.36C104.455 24.3 109.555 24.2 128.635 24.2C147.715 24.2 151.365 27.77 151.365 45.63V76.91H127.765V49.39C127.765 37.8 126.115 37.8 115.335 37.8C104.555 37.8 101.655 39.05 101.655 50.93V76.9H78.0548V25.74H100.595H100.585Z"
          fill={color}
        />
        <path
          d="M155.105 13.49C155.105 9.05 161.465 5.47 169.465 5.47C177.465 5.47 183.715 9.04 183.715 13.49C183.715 17.94 177.355 21.51 169.465 21.51C161.575 21.51 155.105 17.94 155.105 13.49ZM181.215 25.76V76.93H157.615V25.74H181.215V25.76Z"
          fill={color}
        />
        <path
          d="M211.085 36.08C213.985 24.59 220.715 24.21 236.045 24.21C258.885 24.21 267.155 30.39 267.155 50.38C267.155 70.37 258.485 78.47 237.295 78.47C217.845 78.47 213.605 77.79 211.085 68.72V103.68H187.395V25.74H210.125L211.095 36.08H211.085ZM227.355 64.66C242.665 64.66 243.725 60.22 243.725 50.38C243.725 40.54 242.575 37.63 226.865 37.63C212.895 37.63 211.345 40.82 211.345 50.38C211.345 61.48 212.595 64.66 227.335 64.66H227.355Z"
          fill={color}
        />
        <path
          d="M312.685 14.65C304.015 14.65 302.775 16.77 303.335 25.75H315.085V39.27H303.335V76.92H279.735V39.27H269.615V25.75H279.735V19.28C279.735 8.18 282.235 -0.309998 298.525 -0.309998H322.805V14.66H312.685V14.65Z"
          fill={color}
        />
        <path
          d="M357.705 78.47H353.655C319.065 78.47 318.305 66.2 318.305 52.11V50.27C318.305 36.46 318.685 24.19 353.375 24.19H357.705C397.105 24.19 394.985 38.97 394.985 53.34V56.24H341.995C341.715 65.33 346.705 64.93 355.205 64.93H357.235C370.825 64.93 371.205 63.58 371.485 59.43H394.795V59.62C394.795 68.71 397.195 78.45 357.705 78.45V78.47ZM342.185 43.7H371.175C371.175 37.91 366.655 37.71 355.085 37.71C346.325 37.71 342.085 37.71 342.175 43.7H342.185Z"
          fill={color}
        />
        <path
          d="M439.125 78.47H435.075C400.485 78.47 399.725 66.2 399.725 52.11V50.27C399.725 36.46 400.105 24.19 434.785 24.19H439.115C478.515 24.19 476.395 38.97 476.395 53.34V56.24H423.415C423.135 65.33 428.125 64.93 436.625 64.93H438.645C452.235 64.93 452.615 63.58 452.895 59.43H476.205V59.62C476.205 68.71 478.605 78.45 439.115 78.45V78.47H439.125ZM423.605 43.7H452.595C452.595 37.91 448.075 37.71 436.515 37.71C427.755 37.71 423.515 37.71 423.605 43.7Z"
          fill={color}
        />
        <path
          d="M537.285 66.6C534.385 78.09 527.655 78.47 512.325 78.47C489.485 78.47 481.215 72.29 481.215 52.3C481.215 32.31 489.885 24.21 511.075 24.21C530.525 24.21 534.765 24.89 537.285 33.96V-1H560.975V76.92H538.245L537.275 66.58V66.6H537.285ZM521.015 38.02C505.705 38.02 504.645 42.46 504.645 52.3C504.645 62.14 505.795 65.05 521.505 65.05C535.475 65.05 537.025 61.86 537.025 52.3C537.025 41.2 535.775 38.02 521.035 38.02H521.015Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_625_337">
          <rect width="561" height="136" fill="white" />
        </clipPath>
        <clipPath id="clip1_625_337">
          <rect
            width="144.939"
            height="43.7196"
            fill="white"
            transform="translate(269.142 91.9806)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

LogoWordmark.defaultProps = defaultProps
